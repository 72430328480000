// /**
//  * This file includes polyfills needed by Angular and is loaded before the app.
//  * You can add your own extra polyfills to this file.
//  *
//  * This file is divided into 2 sections:
//  *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
//  *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
//  *      file.
//  *
//  * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
//  * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
//  * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
//  *
//  * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
//  */

// /***************************************************************************************************
//  * BROWSER POLYFILLS
//  */

// /** IE9, IE10 and IE11 requires all of the following polyfills. **/
// import 'core-js/es/symbol';
// import 'core-js/es/object';
// import 'core-js/es/function';
// import 'core-js/es/parse-int';
// import 'core-js/es/parse-float';
// import 'core-js/es/number';
// import 'core-js/es/math';
// import 'core-js/es/string';
// import 'core-js/es/date';
// import 'core-js/es/array';
// import 'core-js/es/regexp';
// import 'core-js/es/map';
// import 'core-js/es/weak-map';
// import 'core-js/es/set';

// /** Evergreen browsers require these. **/
// import 'core-js/es/reflect';
// import 'core-js/es/reflect';

// /** ALL Firefox browsers require the following to support `@angular/animation`. **/
// // import 'web-animations-js';  // Run `npm install --save web-animations-js`.

// /***************************************************************************************************
//  * Zone JS is required by Angular itself.
//  */
// import 'zone.js';  // Included with Angular CLI.

// /***************************************************************************************************
//  * APPLICATION IMPORTS
//  */

// /**
//  * Date, currency, decimal and percent pipes.
//  * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
//  */
// //import 'intl';  // Run `npm install --save intl`.
// /**
//  * Need to import at least one locale-data with intl.
//  */
// // import 'intl/locale-data/jsonp/en';

/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';
import 'core-js/es/reflect';
import 'core-js/es/reflect';
import 'core-js/es/array';
import 'core-js/es/object'; // Run `npm install --save classlist.js`. // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */

// (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
// (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
// (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

/*
 * in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 * with the following flag, it will bypass `zone.js` patch for IE/Edge
 */
// (window as any).__Zone_enable_cross_context_check = true;

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js'; // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

//import '@webcomponents/custom-elements/src/native-shim';

//import '@webcomponents/custom-elements/custom-elements.min';

// (function() {
// 	var support = ("content" in document.createElement("template"));

// 	// Set the content property if missing
// 	if (!support) {
// 		var
// 			/**
// 			 * Prefer an array to a NodeList
// 			 * Otherwise, updating the content property of a node
// 			 * will update the NodeList and we'll loose the nested <template>
// 			 */
// 			templates = Array.prototype.slice.call(document.getElementsByTagName("template")),
// 			template, content, fragment, node, i = 0, j;

// 		// For each <template> element get its content and wrap it in a document fragment
// 		while ((template = templates[i++])) {
// 			content  = template.children;
// 			fragment = document.createDocumentFragment();

// 			for (j = 0; node = content[j]; j++) {
// 				fragment.appendChild(node);
// 			}

// 			template.content = fragment;
// 		}
// 	}

// 	// Prepare a clone function to allow nested <template> elements
// 	function clone() {
// 		var
// 			templates = this.querySelectorAll("template"),
// 			fragments = [],
// 			template,
// 			i = 0;

// 		// If the support is OK simply clone and return
// 		if (support) {
// 			template  = this.cloneNode(true);
// 			templates = template.content.querySelectorAll("template");

// 			// Set the clone method for each nested <template> element
// 			for (; templates[i]; i++) {
// 				templates[i].clone = clone;
// 			}

// 			return template;
// 		}

// 		// Loop through nested <template> to retrieve the content property
// 		for (; templates[i]; i++) {
// 			fragments.push(templates[i].content);
// 		}

// 		// Now, clone the document fragment
// 		template = this.cloneNode(true);

// 		// Makes sure the clone have a "content" and "clone" properties
// 		template.content = this.content;
// 		template.clone   = clone;

// 		/**
// 		 * Retrieve the nested <template> once again
// 		 * Since we just cloned the document fragment,
// 		 * the content's property of the nested <template> might be undefined
// 		 * We have to re-set it using the fragment array we previously got
// 		 */
// 		templates = template.querySelectorAll("template");

// 		// Loop to set the content property of each nested template
// 		for (i = 0; templates[i]; i++) {
// 			templates[i].content = fragments[i];
// 			templates[i].clone   = clone; // Makes sure to set the clone method as well
// 		}

// 		return template;
// 	}

// 	var	templates:any = document.querySelectorAll("template"),
// 		template, i = 0;

// 	// Pollute the DOM with a "clone" method on each <template> element
// 	while ((template = templates[i++])) {
// 		template.clone = clone;
// 	}
// }());
